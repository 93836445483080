import React, { useEffect, useReducer, useCallback, useState } from "react";
import Context from "./Context";
import {
  StationModel,
  CenterModel,
  AreaModel,
  AlarmStateModel,
  UserModel,
  UserGroupModel,
} from "app/shared/model";
import { getAll } from "app/shared/util/request";
import { useAuth } from "app/providers/Auth";

const initState = {
  stations: [] as StationModel[],
  userGroups: [] as UserGroupModel[],
  users: [] as UserModel[],
  alarmStates: [] as AlarmStateModel[],
  currentType: "stations",
  currentSelectedUserGroup: "",
};

export type ProviderState = typeof initState;

type AuthAction =
  | { type: "INIT"; payload: Partial<ProviderState> }
  | { type: "GET_USER_GROUPS"; payload: Partial<ProviderState> }
  | { type: "GET_USERS"; payload: Partial<ProviderState> }
  | { type: "GET_STATIONS"; payload: Partial<ProviderState> }
  | { type: "SET_CURRENT_TYPE"; payload: Partial<ProviderState> }
  | {
      type: "SET_CURRENT_SELECTED_USER_GROUP";
      payload: Partial<ProviderState>;
    };

function reducer(state: ProviderState, action: AuthAction) {
  const { type, payload } = action;
  switch (type) {
    case "INIT":
      return payload;
    case "GET_USER_GROUPS":
      return {
        ...state,
        userGroups: payload.userGroups,
      };
    case "GET_USERS":
      return {
        ...state,
        users: payload.users,
      };
    case "GET_STATIONS":
      return {
        ...state,
        stations: payload.stations,
      };
    case "SET_CURRENT_TYPE":
      return {
        ...state,
        currentType: payload.currentType,
      };
    case "SET_CURRENT_SELECTED_USER_GROUP":
      return {
        ...state,
        currentSelectedUserGroup: payload.currentSelectedUserGroup,
      };
    default:
      throw new Error();
  }
}

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [data, dispatch] = useReducer(reducer, initState);

  const { user } = useAuth();

  useEffect(() => {
    if (!user?.id) return;
    const initialize = async () => {
      const [userGroups, alarmStates, users, stations] = await Promise.all([
        getAll<UserGroupModel>("/api/bts/user-groups"),
        // getAll<AreaModel>("/api/bts/user-groups"),
        getAll<AlarmStateModel>("/api/bts/alarm-stats"),
        getAll<UserModel>("/api/bts/users"),
        getAll<StationModel>("/api/bts/stations"),
      ]);
      dispatch({
        type: "INIT",
        payload: {
          userGroups,
          alarmStates,
          users,
          stations,
        },
      });
    };
    (async () => {
      await initialize();
    })();
  }, [user?.id]);

  const getUserGroups = useCallback(async () => {
    const userGroups = await getAll<CenterModel>("/api/bts/user-groups", "r");
    dispatch({
      type: "GET_USER_GROUPS",
      payload: {
        userGroups,
      },
    });
  }, []);

  const getUsers = useCallback(async () => {
    const users = await getAll<UserModel>("/api/bts/uses", "");
    dispatch({
      type: "GET_USERS",
      payload: {
        users,
      },
    });
  }, []);

  const getStations = useCallback(async () => {
    const stations = await getAll<StationModel>("/api/bts/stations");
    dispatch({
      type: "GET_STATIONS",
      payload: {
        stations,
      },
    });
  }, []);

  const setCurrentType = useCallback((currentType: string) => {
    dispatch({
      type: "SET_CURRENT_TYPE",
      payload: {
        currentType,
      },
    });
  }, []);

  const setCurrentSelectedUserGroup = useCallback(
    (currentSelectedUserGroup: string) => {
      dispatch({
        type: "SET_CURRENT_SELECTED_USER_GROUP",
        payload: {
          currentSelectedUserGroup,
        },
      });
    },
    [],
  );

  return (
    <Context.Provider
      value={{
        ...data,
        getUserGroups,
        getStations,
        getUsers,
        setCurrentSelectedUserGroup,
        setCurrentType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
