import axios, { AxiosRequestConfig } from "axios";

const MAX_SIZE = 100000;

export interface PaginationResponseData<TEntity extends unknown> {
  meta: {
    count: number;
  };
  data: Data[];
}

export interface PaginationData<TEntity extends unknown> {
  total: number;
  data: TEntity[];
}

export interface Data {
  type?: string;
  relationships?: unknown;
  attributes?: any;
  id?: string;
  links?: unknown;
}

export interface ResponseData<TEntity extends unknown> {
  data: Data;
}

export async function getAll<T extends unknown>(
  url: string,
  filter?: string,
  config?: AxiosRequestConfig,
): Promise<T[]> {
  const { data } = await axios.get<PaginationResponseData<T>>(
    `${url}${filter ? `?${filter}` : ""}${
      filter
        ? `&page[number]=1&page[size]=${MAX_SIZE}`
        : `?page[number]=1&page[size]=${MAX_SIZE}`
    }`,
    config,
  );
  return data.data.map((item) => ({
    id: item.id,
    ...item.attributes,
  }));
}

export async function getById<T extends unknown>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> {
  const { data } = await axios.get<ResponseData<T>>(`${url}`, config);
  return {
    id: data.data.id,
    ...data?.data.attributes,
  };
}

export async function getWithPagination<T extends unknown>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<PaginationData<T>> {
  const { data } = await axios.get<PaginationResponseData<T>>(url, config);
  return {
    data: data?.data?.map((item) => ({
      id: item.id,
      ...item.attributes,
    })),
    total: data?.meta?.count,
  };
}

export async function updateData<T extends unknown>(
  url: string,
  data: T,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await axios.patch(`${url}`, data, config);
  return response.data;
}

export async function addData<T extends unknown>(
  url: string,
  data: T,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await axios.post(`${url}`, data, config);
  return response.data;
}

export async function deleteData<T extends unknown>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<void> {
  await axios.delete(`${url}`, config);
}

export async function patchData<T extends unknown>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<void> {
  await axios.patch(`${url}`, config);
}

export async function request(url, config?: AxiosRequestConfig) {
  const response = await axios.get(`${url}`, config);
  return response.data;
}

export async function baseRequest(config?: AxiosRequestConfig) {
  return axios.request(config);
}
