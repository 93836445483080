import React, { memo, useCallback } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import ErrorBoundary from "app/shared/error/error-boundary";
import { useAuth } from "app/providers/Auth";

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];

  [key: string]: any;
}

export type IPrivateRouteProps = IOwnProps;

export const hasAnyAuthority = (
  authorities: string[],
  hasAnyAuthorities: string[],
) => {
  if (!hasAnyAuthorities || hasAnyAuthorities?.length === 0) return true;
  if (authorities && authorities.length !== 0) {
    return hasAnyAuthorities.some((auth) => authorities.includes(auth));
  }
  return false;
};

export const PrivateRouteComponent = ({
  component: Component,
  hasAnyAuthorities = [],
  ...rest
}: IPrivateRouteProps) => {
  const { isAuthenticated, isInitialized, authorities } = useAuth();

  const checkAuthorities = useCallback(
    (props) =>
      hasAnyAuthority(authorities, hasAnyAuthorities) ? (
        <ErrorBoundary>
          <Component {...rest} {...props} />
        </ErrorBoundary>
      ) : (
        <div>403</div>
      ),
    [authorities, hasAnyAuthorities],
  );

  const renderRedirect = useCallback(
    (props) => {
      if (!isInitialized) {
        return <div>Loading</div>;
      } else {
        return isAuthenticated ? (
          checkAuthorities(props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
    },
    [isAuthenticated, isInitialized, checkAuthorities],
  );

  if (!Component)
    throw new Error(
      `A component needs to be specified for private route for path ${rest.path}`,
    );

  return <Route {...rest} render={renderRedirect} />;
};

export const PrivateRoute = memo(PrivateRouteComponent);

export default PrivateRoute;
