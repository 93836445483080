import React, { Suspense } from "react";
import { Loading } from "app/shared/components/loading";

const loadRoute = <T extends object = {}>(getPromise): React.FC<T> => {
  const Component = React.lazy(getPromise);
  return (props: T) => {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

export default loadRoute;
