import React, { useEffect, useReducer, useState, useCallback } from "react";
import Context from "./Context";

const initState = {
  collapsed: false,
  collapsedWidth: 56,
  siderWidth: 280,
  headerHeight: 56,
  theme: "light" as ThemeType,
};
export type ThemeType = "light" | "dark";
export type ProviderState = typeof initState;

type AuthAction =
  | { type: "INIT"; payload?: Partial<ProviderState> }
  | { type: "TOGGLE_COLLAPSE"; payload?: void }
  | { type: "CHANGE_THEME"; payload?: Partial<ProviderState> };

function reducer(state: ProviderState, action: AuthAction) {
  const { type, payload } = action;
  switch (type) {
    case "INIT":
      return initState;
    case "TOGGLE_COLLAPSE":
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case "CHANGE_THEME":
      return {
        ...state,
        theme: (payload as ProviderState).theme,
      };
    default:
      throw new Error();
  }
}

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [data, dispatch] = useReducer(reducer, initState);
  const [isMobile, setIsMobile] = useState<boolean>(
    () => window.innerWidth < 1024,
  );

  useEffect(() => {}, []);

  useEffect(() => {
    const initialize = () => {
      dispatch({
        type: "INIT",
        payload: initState,
      });
      if (initState.theme === "light") {
        document.body.classList.remove("dark");
        document.body.classList.add("light");
      } else {
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      }
    };
    initialize();
    const windowSizeChangeHandle = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", windowSizeChangeHandle, true);
    return () => {
      window.removeEventListener("resize", windowSizeChangeHandle, true);
    };
  }, []);

  const toggleCollapse = useCallback(() => {
    dispatch({
      type: "TOGGLE_COLLAPSE",
    });
  }, []);

  const changeTheme = useCallback(() => {
    // if (data.theme === 'light') {
    //   document.body.classList.add('dark');
    //   document.body.classList.remove('light');
    // } else {
    //   document.body.classList.remove('dark');
    //   document.body.classList.add('light');
    // }
    dispatch({
      type: "CHANGE_THEME",
      payload: {
        theme: data.theme === "light" ? "dark" : "light",
      },
    });
  }, [data]);

  return (
    <Context.Provider
      value={{
        ...data,
        isMobile,
        toggleCollapse,
        changeTheme,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
