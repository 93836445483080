import React from "react";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import loadRoute from "app/shared/components/route/loadRoute";
import PrivateRoute from "app/shared/auth/private-route";
import { Switch, Redirect, Route } from "react-router-dom";

const BaseLayout = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <ErrorBoundaryRoute
        path="/login"
        exact
        component={loadRoute(() => import("app/modules/login/Login"))}
      />
      <PrivateRoute
        path="/"
        component={loadRoute(() => import("app/layouts/AdminLayout"))}
      />
    </Switch>
  );
};

export { BaseLayout };
