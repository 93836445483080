import React, { useEffect, useState } from "react";
import Context from "./Context";
import { io, Socket } from "socket.io-client";

const Provider = ({ children }: { children: React.ReactNode }) => {
  const socket: Socket | undefined =
    typeof io === "function"
      ? io("https://mekongsmartbts.vn", {
          path: "/socket.io",
          forceNew: true,
          reconnectionAttempts: 3,
          timeout: 2000,
        })
      : undefined;

  return (
    <Context.Provider
      value={{
        socket,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
