import React from "react";
import { Socket } from "socket.io-client";

interface SocketProviderContextType {
  socket: Socket | undefined;
}

export const Context = React.createContext<SocketProviderContextType>(null);

export default Context;
