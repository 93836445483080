import React, { useEffect, useReducer, useState, useCallback } from "react";
import Context from "./Context";
import { CookieUtils } from "app/shared/util/cookie-utils";
import axios from "axios";
import { UserModel } from "app/shared/model/user.model";

const initState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {} as UserModel,
  authorities: [] as string[],
  error: ''
};

export type ProviderState = typeof initState;

type AuthAction =
  | { type: "LOGIN"; payload: Partial<ProviderState> }
  | { type: "LOGOUT"; payload: void }
  | { type: "ERROR"; payload: Partial<ProviderState> }
  | { type: "INITIALIZE"; payload: Partial<ProviderState> };

function reducer(state: ProviderState, action: AuthAction) {
  const { type, payload } = action;
  switch (type) {
    case "INITIALIZE":
      return {
        isInitialized: true,
        ...payload,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        ...payload,
      };
    case "ERROR":
      return {
        ...state,
        ...payload,
      };
    case "LOGOUT":
      return {
        ...initState,
        isInitialized: true,
      };
    default:
      throw new Error();
  }
}

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [auth, dispatch] = useReducer(reducer, initState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const session = CookieUtils.getCookie("SESSION");
        if (session) {
          const { data } = await axios.get("/me");
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: data,
              authorities: [...data.role.modules, data.role.code],
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = useCallback(async (dataLogin) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/me/login", dataLogin);
      setLoading(false);
      CookieUtils.setCookie("SESSION", data.session.key);
      dispatch({
        type: "LOGIN",
        payload: {
          user: data,
          authorities: [...data.role.modules, data.role.code],
        },
      });
    } catch (e) {
      dispatch({
        type: "LOGIN",
        payload: {
          error: 'Please check your login details and try again.'
        },
      });
      throw e;
    } finally {
      setLoading(false);
    }

  }, []);

  const logout = useCallback(async () => {
    await axios.post("/me/logout");
    CookieUtils.deleteCookie("SESSION");
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
  }, []);

  return (
    <Context.Provider
      value={{
        ...auth,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
