import axios from "axios";
import { CookieUtils } from "app/shared/util/cookie-utils";

// axios.defaults.timeout = 20000;

axios.interceptors.request.use((config) => {
  const sessionToken = CookieUtils.getCookie("SESSION");
  if (sessionToken) {
    config.headers.SESSION = sessionToken;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
    throw error;
  },
);
