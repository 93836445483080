import React from "react";
import { ProviderState, ThemeType } from "./Provider";

interface AppSettingProviderContextType extends Partial<ProviderState> {
  isMobile: boolean;
  toggleCollapse(): void;
  changeTheme(): void;
}

export const Context = React.createContext<AppSettingProviderContextType>(null);

export default Context;
