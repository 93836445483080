import { useTranslation } from "react-i18next";
import useLocalStorage from "app/shared/hooks/useLocalStorage";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "vi",
    label: "Vietnamese",
    icon: "/content/images/flags/vi-VN.png",
  },
  {
    value: "en",
    label: "English",
    icon: "/content/images/flags/en-US.png",
  },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const [currentLang, setCurrentLang] = useLocalStorage("i18nLang", "vi");

  const handleChangeLanguage = (newLang: string) => {
    setCurrentLang(newLang);
    return i18n.changeLanguage(newLang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang: LANGS.find((_lang) => _lang.value === currentLang),
    allLang: LANGS,
  };
}
