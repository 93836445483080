import React from "react";
import { ProviderState } from "./Provider";

interface AuthProviderContextType extends Partial<ProviderState> {
  getUserGroups(): Promise<void>;
  getUsers(): Promise<void>;
  getStations(): Promise<void>;
  setCurrentType(currentType: string): void;
  setCurrentSelectedUserGroup(currentSelectedUserGroup: string): void;
}

export const Context = React.createContext<AuthProviderContextType>(null);

export default Context;
