import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["en", "vi"];
i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "vi",
    lng: localStorage.getItem("i18nLang"),
    backend: {
      loadPath: "https://mekongsmartbts.vn/api/bts/remote-configs",
    },
    debug: true,
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
