import "./style/global.less";

import React from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import "app/config/request";
import "app/config/translation";
import App from "./App";
import { AuthProvider } from "./providers/Auth";
import { AppSettingProvider } from "app/providers/AppSetting";
import { GlobalDataProvider } from "app/providers/GlobalData";
import { SocketProvider } from "./providers/Socket";

const Root = () => {
  return (
    // <React.StrictMode>
    <AuthProvider>
      <SocketProvider>
        <AppSettingProvider>
          <Router>
            <GlobalDataProvider>
              <App />
            </GlobalDataProvider>
          </Router>
        </AppSettingProvider>
      </SocketProvider>
    </AuthProvider>
    // </React.StrictMode>
  );
};

declare let module: Record<string, unknown>;
hot(module)(Root);

render(<Root />, document.getElementById("root"));
