import Context from "./Context";
import React from "react";

const withContainer = (Component) => {
  return (props) => {
    return (
      <Context.Consumer>
        {(value) => {
          return <Component {...value} {...props} />;
        }}
      </Context.Consumer>
    );
  };
};

export default withContainer;
