import React from "react";
import { ProviderState } from "./Provider";

interface AuthProviderContextType extends Partial<ProviderState> {
  login(value: any): Promise<void>;
  logout(): Promise<void>;
  loading: boolean;
}

export const Context = React.createContext<AuthProviderContextType>(null);

export default Context;
